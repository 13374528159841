<template>
  <router-view />
</template>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: rgba(17, 17, 17, 255);
  overflow: hidden;
}
</style>
